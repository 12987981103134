import * as React from "react";
import Layout from "../../components/layout/layout";
import Seo from "../../components/seo";
import {StaticImage} from "gatsby-plugin-image";

import * as styles from "./sluzby.module.scss";

const ServicesPageForIndividuals = () => {
  return (
    <Layout>
      <StaticImage src="../../images/building_sky.jpg"
                   alt="Ilustrace v pozadí"
                   className={styles.background}
                   loading={"eager"}
                   objectPosition={"90% 50%"}
      />
      <Seo title="Služby" />
      <section className={styles.intro}>
        <h1>Inženýring stavebních projektů</h1>
        <p>Řešíme zdánlivě neřešitelná zadání a jsme prostředníkem mezi stavebníky a státní správou. Pomáháme tak najít optimální cestu k realizaci jakéhokoliv stavebního záměru.</p>
      </section>
      <section className={styles.servicesSection}>
        <h2>Nabízíme služby v těchto oblastech:</h2>
        <div className={styles.services}>
          <div className={styles.service}>
            <h3 className={styles.title}>Povolení staveb rodinných domů</h3>
            <p className={styles.description}>
              Kompletní zastoupení v jednání se státní správou, získání všech nutných vyjádření a stanovisek, zajištění finálních povolení umožňujících výstavbu.
            </p>
          </div>
          <div className={styles.service}>
            <h3 className={styles.title}>Dopravní a technická infrastruktura</h3>
            <p className={styles.description}>
              Přípojky vodovodu, kanalizace a plynu.
              Povolení dopravního připojení pozemků na pozemní komunikace.
            </p>
          </div>
          <div className={styles.service}>
            <h3 className={styles.title}>Vodohospodářské stavby</h3>
            <p className={styles.description}>
              Studny & domovní čistírny odpadních vod.
            </p>
          </div>
          <div className={styles.service}>
            <h3 className={styles.title}>Koupě pozemku</h3>
            <p className={styles.description}>
              Technický audit pozemku před jeho koupí. Prověření jeho možnosti zastavění (omezení plynoucí z územního plánu). Prověření možnosti připojení na technickou infrastrukturu. Ochranná pásma.
            </p>
          </div>
          <div className={styles.service}>
            <h3 className={styles.title}>Katastr nemovitostí</h3>
            <p className={styles.description}>
              Pasportizace objektů. Uvedení stavu v katastru nemovitostí do souladu se skutečným stavem.
            </p>
          </div>
          <div className={styles.service}>
            <h3 className={styles.title}>Konzultace</h3>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ServicesPageForIndividuals;
